import React, { useContext, useState } from "react";
import styled from "styled-components";
import Button from "../../nekrasovka-ui/Button";
import JsBarcode from "jsbarcode";
import Section from "../../nekrasovka-ui/Section";
import { Link } from "react-router-dom";
import { AlertContext } from "../../nekrasovka-ui/Alert/AlertProvider";

export default () => {
  const [inputValue, setInputValue] = useState({});
  const { dispatch } = useContext(AlertContext);

  function handleChange(e) {
    setInputValue({ ...inputValue, [e.target.name]: e.target.value });
  }

  function textToBase64Barcode(text) {
    let canvas = document.createElement("canvas");
    let options = {
      format: "EAN13",
      height: 150,
      width: 4,
      margin: 0,
      fontSize: 36,
      font: "Rubik-Regular",
    };

    JsBarcode(canvas, text, options);

    return canvas.toDataURL("image/png");
  }

  function myClick() {
    let a = inputValue["name"];
    let b = inputValue["number"];
    let c = inputValue["otchestvo"];
    let d = inputValue["surname"];

    let front = document.getElementById("front");
    front.classList.add("hidden");

    let canvas = document.getElementById("canvas");
    let picture = canvas.getContext("2d");
    let text = canvas.getContext("2d");
    canvas.classList.remove("hidden");

    let img1 = new Image();
    img1.crossOrigin = "anonymous";
    img1.src = `${process.env.REACT_APP_URL}/images/14.png`;

    img1.onload = function () {
      picture.drawImage(img1, 0, 0, 2021, 1276);

      let font = new FontFace("Rubik-Regular", "url(/fonts/Rubik-Regular.ttf)");

      font.load().then(function () {
        document.fonts.add(font);
        text.font = "normal 100px Rubik-Regular";

        text.fillText(a, 120, 890);
        text.fillText(c, 120, 1020);
        text.fillText(d, 120, 1150);
      });

      let img2 = new Image();
      let encodedB = encodeURIComponent(b);

      img2.src = textToBase64Barcode(encodedB);

      img2.onload = function () {
        picture.drawImage(img2, 1300, 870, 650, 300);

        dispatch({
          type: "ALERT_ON",
          text: `Читательский билет для "${a} ${c} ${d}", сгенерирован.`,
          name: "warning",
        });
      };
    };
  }

  const isDisabled =
    !inputValue["name"] ||
    !inputValue["otchestvo"] ||
    !inputValue["surname"] ||
    !inputValue["number"];

  return (
    <Container>
      <Navigation>
        <div>
          <NavigationButton as={Link} to="/">
            Интранет
          </NavigationButton>
          <span>Генератор читательских билетов</span>
        </div>
      </Navigation>
      <Form action="" method="POST" name="reg_form">
        <div className="word">
          <div className="lab-1">
            <label htmlFor="number">Номер читательского билета</label>
          </div>
          <div className="lab-2">
            <label htmlFor="name">Имя</label>
          </div>
          <div className="lab-3">
            <label htmlFor="otchestvo">Отчество</label>
          </div>
          <div className="lab-4">
            <label htmlFor="surname">Фамилия</label>
          </div>
        </div>
        <div className="znach">
          <div className="box-1">
            <Input
              type="text"
              name="number"
              maxLength="13"
              placeholder="Введите Номер"
              value={inputValue["number"]}
              onChange={handleChange}
            />
          </div>
          <div className="box-2">
            <Input
              type="text"
              name="name"
              placeholder="Введите Имя"
              value={inputValue["name"]}
              onChange={handleChange}
            />
          </div>
          <div className="box-3">
            <Input
              type="text"
              name="otchestvo"
              placeholder="Введите Отчество"
              value={inputValue["otchestvo"]}
              onChange={handleChange}
            />
          </div>
          <div className="box-4">
            <Input
              type="text"
              name="surname"
              placeholder="Введите Фамилию"
              value={inputValue["surname"]}
              onChange={handleChange}
            />
          </div>
        </div>
      </Form>
      <Button name="Сгенерировать" onClick={myClick} isDisabled={isDisabled} />
      <Images>
        <canvas id="canvas" className="img hidden" width="2021" height="1276" />
        <img
          alt="14"
          id="front"
          src={`${process.env.REACT_APP_URL}/images/14.png`}
        />
        <img alt="15" src={`${process.env.REACT_APP_URL}/images/15.png`} />
      </Images>
    </Container>
  );
};

const Container = styled(Section)`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  @font-face {
    font-family: "Rubik-Regular";
    src: url(/fonts/Rubik-Regular.ttf);
  }

  input,
  label,
  button {
    font-size: 13px;
  }

  .hidden {
    display: none;
  }

  img,
  canvas {
    width: 50%;
  }

  button:disabled {
    opacity: 0.5;
  }
`;

const Navigation = styled.div`
  display: flex;
  padding-top: 15px;

  > div {
    :first-child {
      display: flex;
      align-items: center;
      column-gap: 10px;
    }

    :last-child {
      font-size: 13px;
    }
  }

  @media (min-width: 769px) {
    align-items: center;
    justify-content: space-between;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    row-gap: 10px;
  }
`;

const NavigationButton = styled.button`
  display: flex;
  justify-content: center;
  padding: 7px 10px;
  background-color: initial;
  color: #222222;
  border-radius: 5px;
  border: 1px solid #c4c4c4;
  cursor: pointer;
  white-space: nowrap;
`;

const Images = styled.div`
  display: flex;
  column-gap: 10px;
`;

const Form = styled.div`
  display: grid;
  grid-template-columns: auto 100%;
  column-gap: 20px;
  width: 40%;

  > div {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    white-space: nowrap;

    > div {
      display: flex;
      align-items: center;
      height: 40px;
    }
  }
`;

const Input = styled.input`
  height: 100%;
  width: 100%;
  padding-left: 20px;

  border: 1px solid #c4c4c4;
  margin: 0;
  color: #222222;
  background-color: #ffffff;
`;
