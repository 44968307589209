import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Button from "../../nekrasovka-ui/Button";
import { AlertContext } from "../../nekrasovka-ui/Alert/AlertProvider";
import Icon from "../../nekrasovka-ui/Icon";
import Separator from "../../nekrasovka-ui/Separator";
import { useWindowDimensions } from "../../helpers";
import TableBooksNotFound from "./Components/TableBooksNotFound";
import MobileBooksNotFound from "./Components/MobileBooksNotFound";
import axios from "axios";
import { connect } from "react-redux";
import {
  clearProgress,
  closeProgress,
  finishProgress,
  startProgress,
} from "../../reducers/progress/actions";
import Calendar from "react-calendar";
import format from "date-fns/format";
import locale from "date-fns/locale/ru";
import * as XLSX from "xlsx";

const BooksNotFound = ({
  tokenParsed,
  checkTokenExpiredYesUpdate,
  startProgress,
  finishProgress,
  closeProgress,
  clearProgress,
}) => {
  const { dispatch } = useContext(AlertContext);
  const { width } = useWindowDimensions();
  const pageSize = width < 700 ? 10 : 20;
  const today = new Date();

  const [inputWidth, setInputWidth] = useState(0);
  const searchRef = useRef(null);
  const [barcode, setBarcode] = useState("");
  const [query, setQuery] = useState("");
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [verificationOpen, setVerificationOpen] = useState(null);
  const [filters, setFilters] = useState({
    submitters: [],
    finders: [],
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [isCalendar, setIsCalendar] = useState(false);
  const [date, setDate] = useState(null);
  const [hiddenHeaders, setHiddenHeaders] = useState([
    "Дата",
    "Баркод или инвентарный номер",
    "Автор и название",
    "Email сотрудника ОБИО, который вёл поиск книги",
    "Статус поиска книги",
    "Комментарии",
  ]);

  const head = [
    "Дата",
    "Баркод или инвентарный номер",
    "Автор и название",
    "Местонахождение",
    "Email сотрудника ОБИО, который вёл поиск книги",
    "Статус поиска книги",
    "Email сотрудника ОБФ, который обновил статус поиска книги",
    "Комментарии",
  ];

  const statuses = ["UNKNOWN", "NOT_PROCESSED", "FOUND", "LOST"];

  const verification = {
    0: "Не проверено",
    1: "Найдено",
    2: "Помечено на списание",
  };

  const getRequest = async (end, method, data) => {
    let { refreshed, TOKEN_KEY } = await checkTokenExpiredYesUpdate();

    if (refreshed) {
      localStorage.setItem("TOKEN_KEY", TOKEN_KEY);
    } else TOKEN_KEY = localStorage.getItem("TOKEN_KEY");

    const headers = { Authorization: `Bearer ${TOKEN_KEY}` };
    const url = `${process.env.REACT_APP_API}/${end}`;
    const options = { method, data, url, headers };

    return axios(options);
  };

  const exportXLSX = () => {
    const promptValue = "Введите имя файла без расширения (необязательно)";

    const objectStrings = Object.entries(selectedFilters)
      .map(([key, value]) => {
        if (key === "status") value = statuses[value + 1];

        return `filters[${key}]=${value}`;
      })
      .join("&");

    clearProgress();
    startProgress();

    getRequest("booksnotfound", "post", {
      end: `books/paginate?${objectStrings}`,
      method: "get",
    })
      .then((resp) => {
        if (!!resp.data.data.length) {
          const json = resp.data.data.map((ch, i) => {
            const titleAndAuthor = `${ch.title}${ch.author ? ", " + ch.author : ""}`;
            const locationHICM = Array.from(
              Object.entries(ch.location)
                .filter(([_, value]) => value !== null)
                .map(([_, value]) => value),
            ).join(", ");

            return {
              ["№"]: i + 1,
              [head[0]]: ch.created_at,
              [head[1]]: ch.barcode,
              [head[2]]: titleAndAuthor,
              [head[3]]: locationHICM,
              [head[4]]: ch.submitter,
              [head[5]]: verification[ch.status - 1],
              [head[6]]: ch.finder,
              [head[7]]: ch.comment,
            };
          });

          const fileName = prompt(promptValue, "") || "SheetJSExportAOO";

          const ws = XLSX.utils.json_to_sheet(json);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
          XLSX.writeFile(wb, `${fileName}.xlsx`);

          finishProgress();
          setTimeout(() => closeProgress(), 300);
        } else {
          finishProgress();
          setTimeout(() => closeProgress(), 300);

          dispatch({
            type: "ALERT_ON",
            name: "error",
            text: "Ничего не найдено",
          });
        }
      })
      .catch((error) => console.error(error));
  };

  const handleGetBarcodes = () => {
    clearProgress();
    startProgress();

    getRequest("booksnotfound", "post", {
      end: `books/lookup`,
      method: "post",
      data: { searchTerm: barcode },
    }).then((resp) => {
      if (resp.data.code === 2002) {
        const text = `Баркод или инвентарный номер отсутствует в OPAC:<br/><br/>${barcode}<br/><br/>Пожалуйста, перепроверьте правильность ввода указанного значения, и внесите правильное значение заново.`;

        dispatch({
          type: "ALERT_ON",
          name: "error",
          text,
        });
      }

      if (resp.data.code === 1100) {
        const temp = [...data];

        temp.unshift(resp.data.data);
        setData(temp);
        setBarcode("");
      }

      finishProgress();
      setTimeout(() => closeProgress(), 300);
    });
  };

  const handleSearch = ({ target }) => {
    const value = !!target.value ? target.value : "";

    setQuery(value);
    handleFilter("searchTerm", value);

    if (!value) setInputWidth(0);
  };

  const handleDate = async (d) => {
    const isActive = !!date && d.getDate() === date.getDate();
    const newDate = isActive ? null : d;
    const created_at = format(d, "yyyy-MM-dd", { locale });

    handleFilter("created_at", created_at, isActive);
    setDate(newDate);
  };

  const handleVerification = (dataIndex, id) => {
    const temp = [...data];
    temp[dataIndex].status = +id + 1;
    temp[dataIndex].finder = tokenParsed.email;

    getRequest("booksnotfound", "post", {
      end: `books/${temp[dataIndex].id}/status`,
      method: "patch",
      data: { status: statuses[+id + 1] },
    });

    setData(temp);
    handleVerificationClose();
  };

  const handleVerificationClose = () => {
    setVerificationOpen(null);
  };

  const handleTypeBarcode = ({ target }) => {
    const value = target.value;

    const pattern1 = /^209.*/;
    const isPattern1 = pattern1.test(value);
    const isMoreThan13 = value.length > 13;

    if (isPattern1) {
      dispatch({
        type: "ALERT_ON",
        text: `Нельзя, чтобы введенное значение начиналось с 209`,
        name: "warning",
      });
    } else if (isMoreThan13) {
      dispatch({
        type: "ALERT_ON",
        text: `Не больше 13 символов`,
        name: "warning",
      });
    } else setBarcode(value);
  };

  const handlePreviousClick = () => {
    if (currentPage > 1) {
      clearProgress();
      startProgress();

      getRequest("booksnotfound", "post", {
        end: `books/paginate?pageSize=${pageSize}&page=${currentPage - 1}`,
        method: "get",
      }).then((resp) => {
        setData(resp.data.data);

        finishProgress();
        setTimeout(() => closeProgress(), 300);
      });

      setCurrentPage(currentPage - 1);
      location.href = "#books_not_found_table";
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      clearProgress();
      startProgress();

      getRequest("booksnotfound", "post", {
        end: `books/paginate?pageSize=${pageSize}&page=${currentPage + 1}`,
        method: "get",
      }).then((resp) => {
        setData(resp.data.data);

        finishProgress();
        setTimeout(() => closeProgress(), 300);
      });

      setCurrentPage(currentPage + 1);
      location.href = "#books_not_found_table";
    }
  };

  const handleFilter = (name, value, isActive = false) => {
    if (isActive) {
      const temp = { ...selectedFilters };
      delete temp[name];

      setSelectedFilters(temp);
    } else
      setSelectedFilters({
        ...selectedFilters,
        [name]: value,
      });
  };

  const handleComment = (index, value) => {
    const temp = [...data];
    temp[index].comment = value;

    getRequest("booksnotfound", "post", {
      end: `books/${temp[index].id}/comment`,
      method: "patch",
      data: { comment: value },
    });

    setData(temp);
  };

  const handleHideHeader = (item) => {
    const isHidden = hiddenHeaders.includes(item);

    if (isHidden) {
      const temp = [...hiddenHeaders];
      temp.splice(temp.indexOf(item), 1);
      setHiddenHeaders(temp);
    } else {
      const temp = [...hiddenHeaders];
      temp.push(item);

      setHiddenHeaders(temp);
    }
  };

  useEffect(() => {
    if (searchRef.current) {
      if (width > 700) {
        setInputWidth(searchRef.current.scrollWidth);
      } else {
        if (width > 0) {
          setInputWidth(0);
        }
      }
    }
  }, [query, searchRef, width]);

  useEffect(() => {
    const objectStrings = Object.entries(selectedFilters)
      .map(([key, value]) => {
        if (key === "status") value = statuses[value + 1];

        return `filters[${key}]=${value}`;
      })
      .join("&");

    clearProgress();
    startProgress();

    getRequest("booksnotfound", "post", {
      end: `books/paginate?pageSize=${pageSize}&${objectStrings}`,
      method: "get",
    }).then((resp) => {
      setData(resp.data.data);
      setTotalPages(resp.data.pages.total);

      finishProgress();
      setTimeout(() => closeProgress(), 300);
    });
  }, [selectedFilters]);

  useEffect(() => {
    clearProgress();
    startProgress();

    getRequest("booksnotfound", "post", {
      end: `books/paginate?pageSize=${pageSize}`,
      method: "get",
    }).then((resp) => {
      setData(resp.data.data);
      setTotalPages(resp.data.pages.total);

      finishProgress();
      setTimeout(() => closeProgress(), 300);
    });

    getRequest("booksnotfound", "post", {
      end: "system/filters",
      method: "get",
    }).then((resp) => {
      setFilters(resp.data.data);
    });
  }, []);

  const isOBIO = tokenParsed.groups[0].split("/")[2] === "ОБИО";
  const isOUBF = tokenParsed.groups[0].split("/")[2] === "ОУБФ";

  return (
    <Container>
      <ContainerHeader>
        <Barcode>
          <h4>Баркод или инвентарный номер</h4>
          <div>
            <input
              disabled={!isOBIO}
              name="barcode"
              type="text"
              size={barcode.length + 3}
              value={barcode}
              placeholder="Баркод или инвентарный номер"
              onChange={handleTypeBarcode}
            />
            <Button
              isDisabled={!barcode}
              name="Добавить"
              onClick={handleGetBarcodes}
            />
          </div>
        </Barcode>
        <TableSearch className="booksnotfound-search" inputWidth={inputWidth}>
          <h4>Поиск по баркоду</h4>
          <div>
            <input
              ref={searchRef}
              type="text"
              value={query}
              placeholder="Поиск по баркоду"
              onChange={handleSearch}
            />
            {!!query ? (
              <div onClick={handleSearch}>
                <Icon
                  icon="closeModal"
                  width={16}
                  height={16}
                  fill="rgb(119, 119, 119)"
                />
              </div>
            ) : (
              <Icon
                icon="search"
                width={16}
                height={16}
                fill="rgb(119, 119, 119)"
              />
            )}
          </div>
        </TableSearch>
        <div onClick={exportXLSX}>
          <Icon icon="xlsx" fill="rgb(36, 98, 122)" height={33} />
        </div>
      </ContainerHeader>
      <BooksNotFoundSeparator />
      <TableFilters isFiltersOpen={isFiltersOpen}>
        <h4
          onClick={() => {
            setIsFiltersOpen(!isFiltersOpen);
          }}
        >
          Фильтры
        </h4>
        <div>
          <div>
            <div onClick={() => setIsCalendar(!isCalendar)}>
              <span>{head[0]}</span>
              <NavigationCalendar>
                <Calendar
                  selectRange={false}
                  onChange={handleDate}
                  value={date}
                  maxDate={today}
                  locale="ru-RU"
                />
              </NavigationCalendar>
            </div>
          </div>
          <div>
            <div>
              <span>{head[4]}</span>
              <div>
                {filters.finders.map((item, index) => {
                  const isActive = selectedFilters["finder"] === item;

                  return (
                    <TableFiltersButton
                      key={index}
                      name={item}
                      isActive={isActive}
                      onClick={() => handleFilter("finder", item, isActive)}
                    />
                  );
                })}
              </div>
            </div>
            <div>
              <span>{head[6]}</span>
              <div>
                {filters.submitters.map((item, index) => {
                  const isActive = selectedFilters["submitter"] === item;

                  return (
                    <TableFiltersButton
                      key={index}
                      name={item}
                      isActive={isActive}
                      onClick={() => handleFilter("submitter", item, isActive)}
                    />
                  );
                })}
              </div>
            </div>
            <div>
              <span>Показывать</span>
              <div>
                {head.map((item, index) => {
                  const isActive = hiddenHeaders.find((f) => f === item);

                  return (
                    <TableFiltersButton
                      key={index}
                      name={item}
                      isActive={isActive}
                      onClick={() => handleHideHeader(item)}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </TableFilters>
      {width < 700 && (
        <MobileCollapseButtonsContainer>
          <MobileCollapseButton
            isActive={isFiltersOpen}
            onClick={() => {
              setIsFiltersOpen(!isFiltersOpen);
            }}
            name={isFiltersOpen ? "Свернуть фильтры" : "Фильтры"}
          />
        </MobileCollapseButtonsContainer>
      )}
      <OutsideFilters>
        <span>Статус поиска книги</span>
        <div>
          {["Не проверено", "Найдено", "Помечено на списание"].map(
            (item, index) => {
              const isActive = selectedFilters["status"] === index;

              return (
                <TableFiltersButton
                  key={index}
                  name={item}
                  isActive={isActive}
                  onClick={() => handleFilter("status", index, isActive)}
                />
              );
            },
          )}
        </div>
      </OutsideFilters>
      {width > 700 ? (
        <TableBooksNotFound
          head={head}
          data={data}
          handlePreviousClick={handlePreviousClick}
          handleNextClick={handleNextClick}
          currentPage={currentPage}
          totalPages={totalPages}
          handleComment={handleComment}
          verification={verification}
          verificationOpen={verificationOpen}
          setVerificationOpen={setVerificationOpen}
          handleVerification={handleVerification}
          handleVerificationClose={handleVerificationClose}
          hiddenHeaders={hiddenHeaders}
          isOUBF={isOUBF}
        />
      ) : (
        <MobileBooksNotFound
          head={head}
          data={data}
          handlePreviousClick={handlePreviousClick}
          handleNextClick={handleNextClick}
          currentPage={currentPage}
          totalPages={totalPages}
          handleComment={handleComment}
          verification={verification}
          verificationOpen={verificationOpen}
          setVerificationOpen={setVerificationOpen}
          handleVerification={handleVerification}
          handleVerificationClose={handleVerificationClose}
          hiddenHeaders={hiddenHeaders}
          isOUBF={isOUBF}
        />
      )}
    </Container>
  );
};

const mapStateToProps = ({
  allowance: { tokenParsed, checkTokenExpiredYesUpdate },
}) => ({
  tokenParsed,
  checkTokenExpiredYesUpdate,
});

export default connect(mapStateToProps, {
  startProgress,
  finishProgress,
  closeProgress,
  clearProgress,
})(BooksNotFound);

const OutsideFilters = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;

  > div {
    display: flex;
    column-gap: 10px;
  }

  > span {
    font-size: 13px;
    font-weight: 500;
  }

  @media (max-width: 700px) {
    > div {
      padding: 0 15px;

      > button {
        width: 100%;
      }
    }

    > span {
      display: none;
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  height: fit-content;

  h4 {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
  }

  @media (min-width: 769px) {
    padding: 20px 15px 20px 30px;
    margin-right: 30px;
  }

  @media (max-width: 768px) and (min-width: 701px) {
    padding: 20px 15px;
    margin-right: 15px;
  }

  @media (min-width: 701px) {
    max-width: 100%;

    border-bottom: 1px solid rgb(196, 196, 196);
    border-top: 1px solid rgb(196, 196, 196);

    border-right: 1px;
    border-right-style: solid;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    border-right-color: rgb(196, 196, 196);
  }

  @media (max-width: 700px) {
    width: 100%;
    padding-top: 20px;
  }
`;

const TableFiltersButton = styled(Button)`
  padding: 4px 8px;
  border-radius: 5px;
  white-space: nowrap;

  @media (hover: hover) {
    :hover {
      background-color: #dddbcf !important;
      color: #222222 !important;
    }
  }

  ${({ isActive }) =>
    isActive &&
    "background-color: #dddbcf !important; color: #222222 !important;"};
`;

const TableFilters = styled.div`
  flex-direction: column;
  row-gap: 15px;
  font-size: 13px;

  > div {
    > div {
      > div {
        display: flex;
        flex-direction: column;
        row-gap: 15px;

        :not(.booksnotfound-search) > div {
          display: flex;
          gap: 10px;
          flex-wrap: wrap;
        }
      }
    }
  }

  @media (min-width: 701px) {
    display: flex;

    h4 {
      cursor: pointer;
      width: fit-content;

      ::after {
        display: inline-block;
        content: "▼";
        font-size: 10px;
        margin-left: 15px;
        ${({ isFiltersOpen }) => isFiltersOpen && "transform: rotate(180deg);"};
      }
    }

    > div {
      display: ${({ isFiltersOpen }) => (isFiltersOpen ? "flex" : "none")};
      column-gap: 20px;

      > div {
        :nth-child(2) {
          display: flex;
          flex-wrap: wrap;
          gap: 15px;
          width: 100%;

          > div {
            :not(:nth-child(3)) {
              flex-basis: calc(100% / 2 - 7.5px);
            }
          }
        }

        > div {
          > span {
            font-weight: 500;
          }
        }
      }
    }
  }

  @media (max-width: 700px) {
    display: ${({ isFiltersOpen }) => (isFiltersOpen ? "flex" : "none")};
    padding: 0 15px;

    > div {
      display: flex;
      flex-direction: column;
      row-gap: 20px;

      > div {
        :nth-child(2) {
          display: flex;
          flex-direction: column;
          row-gap: 15px;
        }
      }
    }

    h4 {
      display: none;
    }
  }
`;

const TableSearch = styled.div`
  display: flex;

  > div {
    display: flex;
    position: relative;
    width: 100%;

    input {
      height: 33px;
      padding: 5px 35px 5px 10px;
      border: 1px solid rgb(196, 196, 196);

      @media (min-width: 701px) {
        ::placeholder {
          color: transparent;
        }
      }
    }

    svg {
      position: absolute;
      right: 10px;
      top: calc(50% - 8px);
    }
  }

  @media (hover: hover) {
    svg {
      cursor: pointer;
    }
  }

  @media (min-width: 701px) {
    align-items: start;

    > div input {
      ${({ inputWidth }) => inputWidth && `width: ${inputWidth}px;`};
      min-width: 100%;
    }
  }

  @media (max-width: 700px) {
    width: 100%;

    > div input {
      width: 100%;
    }

    h4 {
      display: none;
    }
  }
`;

const Barcode = styled.div`
  display: flex;

  input {
    padding: 5px 10px;
    font-size: 13px;
    border: 1px solid rgb(196, 196, 196);
  }

  button {
    height: 100%;

    :disabled {
      opacity: 0.5;
    }
  }

  > div {
    display: flex;
    column-gap: 10px;
    height: 33px;
    width: 100%;
  }

  @media (min-width: 701px) {
    display: flex;
    width: fit-content;
    flex-direction: column;
    row-gap: 15px;

    input {
      min-width: 131px;
      width: 100%;

      ::placeholder {
        color: transparent;
      }
    }
  }

  @media (max-width: 700px) {
    h4 {
      display: none;
    }

    input {
      width: 100%;
    }
  }
`;

const MobileCollapseButtonsContainer = styled.div`
  display: flex;
  column-gap: 10px;
  padding: 0 15px;
`;

const MobileCollapseButton = styled(Button)`
  font-size: 13px;
  padding: 4px 8px;
  width: 100%;
  border-radius: 5px;
  ${({ isActive }) =>
    isActive &&
    "background-color: #dddbcf !important; color: #222222 !important;"};
`;

const ContainerHeader = styled.div`
  > div {
    :not(:nth-child(3)) {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
    }

    :nth-child(3) {
      align-self: end;
      margin-left: auto;
      cursor: pointer;
    }
  }

  font-size: 13px;

  button {
    border-radius: 5px;
  }

  @media (min-width: 701px) {
    display: flex;
    column-gap: 20px;

    > div {
      :nth-child(3) {
        display: flex;
      }
    }

    > h4 {
      display: none;
    }
  }

  @media (max-width: 700px) {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    padding: 0 15px;

    > div {
      width: 100%;

      :nth-child(3) {
        display: none;
      }
    }

    > h4 {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid rgb(196, 196, 196);
      border-radius: 5px;
      font-weight: normal;
      font-size: 13px;
      padding: 4px 8px;
    }
  }
`;

const BooksNotFoundSeparator = styled(Separator)`
  min-width: 100%;

  @media (max-width: 700px) {
    display: none;
  }
`;

const NavigationCalendar = styled.div`
  margin-bottom: 10px;

  .react-calendar {
    display: block;
    width: 100%;
    border: none;
    border-radius: 5px;

    button {
      font-size: 16px;

      :not(.react-calendar__month-view__days__day--weekend) {
        color: #222222;
      }

      :disabled {
        background-color: #f0f0f0;
        opacity: 0.4;
      }
    }

    .react-calendar__tile--active,
    .react-calendar__tile--hasActive {
      color: #ffffff !important;
      background-color: #3c8dbc !important;
    }

    .react-calendar__tile--now:not(.react-calendar__tile--active) {
      color: #3c8dbc !important;
      background-color: #edeee9 !important;
    }
  }

  .react-calendar__navigation {
    height: 36px;
    margin-bottom: 0;
    position: relative;
  }

  .react-calendar__month-view__weekdays abbr {
    text-decoration: none;
  }

  @media (hover: hover) {
    .react-calendar button:hover {
      :not([disabled]) {
        background-color: #40677e !important;
        color: #ffffff !important;
        z-index: 111;
      }
    }
  }

  @media (min-width: 701px) {
    width: 400px;
  }

  @media (max-width: 700px) {
    .react-calendar {
      border-radius: 5px;
    }
  }
`;
