import styled from "styled-components";
import format from "date-fns/format";
import locale from "date-fns/locale/ru";
import React, { useEffect, useState } from "react";
import Bookcases from "./components/Bookcases";
import Filters from "./components/Filters";
import Sorting from "./components/Sorting";
import { floors } from "../../database/dedusting";
import { Link } from "react-router-dom";
import { useWindowDimensions } from "../../helpers";
import Icon from "../../nekrasovka-ui/Icon";
import axios from "axios";
import { connect } from "react-redux";
import Users from "./components/Users";
import Maps from "./components/Maps";

const Dedusting = ({ checkTokenExpiredYesUpdate }) => {
  const date_time = new Date();
  const today = format(date_time, "dd.MM.yyyy", { locale });
  const { width } = useWindowDimensions();

  const [isCalendar, setCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [isFloor, setFloor] = useState(false);
  const [selectedFloor, setSelectedFloor] = useState("");
  const [isUser, setUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);
  const [sorting, setSorting] = useState("");
  const [clickedFloor, setClickedFloor] = useState(null);
  const [isDesktop, setDesktop] = useState(width > 390 * 3);
  const [isFilters, setFilters] = useState(isDesktop);
  const [view, setView] = useState("1");
  const [hiddenView, setHiddenView] = useState([]);
  const [members, setMembers] = useState([]);
  const [bookcases, setBookcases] = useState([]);
  const [bookcasesUsers, setBookcasesUsers] = useState([]);

  const handleDate = (d) => {
    const date = format(d, "dd.MM.yyyy", { locale });

    setCalendar(false);
    setSelectedDate(date === selectedDate ? "" : date);
  };

  const handleFloor = (_, id) => {
    setSelectedFloor(id === selectedFloor ? "" : id);
    setFloor(false);
  };

  const handleUser = (groupName, id) => {
    const temp = selectedUser.includes(id)
      ? selectedUser.filter((item) => item !== id)
      : [...selectedUser, id];

    setSelectedUser(temp);
  };

  const handleFilters = () => !isDesktop && setFilters(!isFilters);

  const handleSorting = (key) => setSorting(key === sorting ? "" : key);
  const handleView = (key) => setView(key);

  const handleBookcase = () => {};

  const handleHideView = (key) => {
    const temp = hiddenView.includes(key)
      ? hiddenView.filter((f) => f !== key)
      : [...hiddenView, key];

    setHiddenView(temp);
  };

  const getRequest = async (end, method, data) => {
    let { refreshed, TOKEN_KEY } = await checkTokenExpiredYesUpdate();

    if (refreshed) {
      localStorage.setItem("TOKEN_KEY", TOKEN_KEY);
    } else TOKEN_KEY = localStorage.getItem("TOKEN_KEY");

    const headers = { Authorization: `Bearer ${TOKEN_KEY}` };
    const url = `${process.env.REACT_APP_API}/${end}`;
    const options = { method, data, url, headers };

    return axios(options);
  };

  const getMembers = () => {
    getRequest("users", "post", { department_id: 3 }).then((resp) => {
      setMembers(resp.data);
    });
  };

  const getCards = () => {
    const options = { where: { bookcases: { point_id: "CUNB_ENT" } } };

    if (selectedFloor !== "") {
      options.where.bookcases.floor_id = selectedFloor + 1;
    }

    if (selectedDate !== "") {
      options.where.bookcases.date = selectedDate;
    }

    if (!!selectedUser.length) {
      options.where.bookcases.user = selectedUser.map((m) => {
        return members[m].login;
      });
    }

    axios
      .post(`${process.env.REACT_APP_API}/bookcases/get`, {
        ...options,
      })
      .then((response) => {
        const data = response.data
          .sort((a, b) => a.percent - b.percent)
          .map((a) => {
            const point = "Главное здание";

            return {
              point,
              floor: a.bookcase.floor_id,
              number: a.bookcase.id,
              image: a.bookcase.image,
              period: a.bookcase.period,
              dates: a.dates,
              percent: a.percent,
              name: a.users.join(", "),
            };
          });

        setBookcases(data);
      });
  };

  const getUsers = () => {
    const options = { where: { point_id: "CUNB_ENT" } };

    if (selectedFloor !== "") {
      options.where.floor_id = selectedFloor + 1;
    }

    if (selectedDate !== "") {
      options.where.date = selectedDate;
    }

    if (!!selectedUser.length) {
      options.where.user = selectedUser.map((m) => {
        return members[m].login;
      });
    }

    axios
      .post(`${process.env.REACT_APP_API}/bookcases/users/get`, {
        ...options,
      })
      .then((response) => {
        setBookcasesUsers(response.data);
      });
  };

  useEffect(() => {
    getMembers();
  }, []);

  useEffect(() => {
    getCards();
    getUsers();
  }, [selectedFloor, selectedUser, selectedDate]);

  useEffect(() => {
    setDesktop(width > 390 * 3);
  }, [width]);

  useEffect(() => {
    if (isDesktop && !isFilters) {
      setFilters(true);
    }
  }, [isDesktop]);

  return (
    <Container>
      <Navigation>
        <div>
          <BookcaseButton as={Link} to="/">
            Интранет
          </BookcaseButton>
        </div>
        <div>
          <BookcaseButton as={Link} to="/backoffice/dedusting/statistics">
            Статистика
          </BookcaseButton>
          <BookcaseButton as={Link} to="/backoffice/dedusting/bookcase/create">
            Добавить ряд
          </BookcaseButton>
        </div>
      </Navigation>
      <Filters
        handleFloor={handleFloor}
        handleFilters={handleFilters}
        isFilters={isFilters}
        selectedFloor={selectedFloor}
        setFloor={setFloor}
        isFloor={isFloor}
        selectedUser={selectedUser}
        setUser={setUser}
        handleUser={handleUser}
        isUser={isUser}
        selectedDate={selectedDate}
        setCalendar={setCalendar}
        isCalendar={isCalendar}
        handleDate={handleDate}
        isDesktop={isDesktop}
        assigned={members.map((mem) => mem.name)}
      />
      <Sorting
        isDesktop={isDesktop}
        sorting={sorting}
        handleSorting={handleSorting}
        view={view}
        handleView={handleView}
      />
      <Views hiddenView={hiddenView}>
        {(isDesktop || view === "1") && (
          <div>
            <ViewTitle onClick={() => (isDesktop ? handleHideView("1") : null)}>
              {isDesktop ? (
                !hiddenView.includes("1") ? (
                  <>
                    Ряды <span>(можно свернуть)</span>
                  </>
                ) : (
                  <Icon icon="filtersList" height="20" width="20" />
                )
              ) : (
                "Ряды"
              )}
            </ViewTitle>
            {!hiddenView.includes("1") && (
              <Bookcases data={bookcases} today={today} />
            )}
          </div>
        )}
        {(isDesktop || view === "2") && (
          <div>
            <ViewTitle onClick={() => (isDesktop ? handleHideView("2") : null)}>
              {isDesktop ? (
                !hiddenView.includes("2") ? (
                  <>
                    Карта этажей <span>(можно свернуть)</span>
                  </>
                ) : (
                  <Icon icon="filtersMozaic" height="20" width="20" />
                )
              ) : (
                "Карта этажей"
              )}
            </ViewTitle>
            {!hiddenView.includes("2") && (
              <Maps
                clickedFloor={clickedFloor}
                setClickedFloor={setClickedFloor}
                handleBookcase={handleBookcase}
                floors={floors}
                selectedFloor={selectedFloor}
              />
            )}
          </div>
        )}
        {(isDesktop || view === "3") && (
          <div>
            <ViewTitle onClick={() => (isDesktop ? handleHideView("3") : null)}>
              {isDesktop ? (
                !hiddenView.includes("3") ? (
                  <>
                    Сотрудники <span>(можно свернуть)</span>
                  </>
                ) : (
                  <Icon icon="calendarPlaner" height="20" width="20" />
                )
              ) : (
                "Сотрудники"
              )}
            </ViewTitle>
            {!hiddenView.includes("3") && <Users data={bookcasesUsers} />}
          </div>
        )}
      </Views>
    </Container>
  );
};

const mapStateToProps = ({ allowance: { checkTokenExpiredYesUpdate } }) => ({
  checkTokenExpiredYesUpdate,
});

export default connect(mapStateToProps)(Dedusting);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;

  @media (min-width: 769px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const Views = styled.div`
  @media (min-width: 431px) {
    display: flex;
    padding: 0 15px;
    column-gap: 10px;

    > div {
      :nth-child(1) {
        width: ${({ hiddenView }) =>
          hiddenView.includes("1") ? "auto" : "100%"};
      }
      :nth-child(2) {
        width: ${({ hiddenView }) =>
          hiddenView.includes("2") ? "auto" : "100%"};
      }
      :nth-child(3) {
        width: ${({ hiddenView }) =>
          hiddenView.includes("3") ? "auto" : "100%"};
      }
    }
  }

  @media (max-width: 390px) {
    display: flex;
    flex-direction: column;
  }
`;

const ViewTitle = styled.div`
  display: flex;
  height: 20px;
  column-gap: 10px;
  align-items: center;
  cursor: pointer;

  ${({ isHidden }) =>
    isHidden
      ? "fill: #c4c4c4;"
      : "padding: 0 0 0 15px; > span {font-size: 11px;}"};
`;

const Navigation = styled.div`
  display: flex;
  padding: 15px;

  > div {
    :first-child {
      display: flex;
      align-items: center;
      column-gap: 10px;
    }

    :last-child {
      display: flex;
      column-gap: 10px;

      button {
        min-width: 150px;
      }
    }
  }

  @media (min-width: 769px) {
    align-items: center;
    justify-content: space-between;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    row-gap: 10px;
  }
`;

const BookcaseButton = styled.button`
  display: flex;
  justify-content: center;
  padding: 7px 10px;
  background-color: initial;
  color: #222222;
  border-radius: 5px;
  border: 1px solid #c4c4c4;
  cursor: pointer;
  white-space: nowrap;
`;
